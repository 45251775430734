<div class="forgot-password">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-screen">
    <div
      class="
        col-span-1
        md:col-span-1
        lg:col-span-2
        p-10
        md:block
        bg-gray-100
        hidden
      "
    >
      <div class="flex justify-between items-center">
        <img src="@/assets/icons/logo.svg" alt="" class="w-14 h-14" />
      </div>
      <div class="flex items-center justify-center">
        <img src="@/assets/forgot-password.svg" alt="photo" class="p-10" />
      </div>
    </div>

    <div class="p-10 md:p-14">
      <!-- <div class="flex justify-end">
        <changeLanguage />
      </div> -->
      <div class="h-full flex items-center justify-center">
        <div>
          <h3 class="font-500 text-xl mb-2">
            {{$t("auth.forgotpassword.forgot")}}
          </h3>
          <p class="enter-email text-base font-400 mb-5">
            {{$t("auth.forgotpassword.enterpass")}}
          </p>

          <div class="mt-6">
            <Form
              as="el-form"
              :validation-schema="forgotPassword"
              @submit="getLink()"
            >
              <label>Email</label>
              <InputWithValidation
                label="Email"
                v-model="email"
                name="email"
              />

              <div class="mt-10">
                <button
                  class="
                    bg-blue-500
                    hover:bg-blue-700
                    text-white
                    font-bold
                    py-2
                    px-4
                    rounded
                  "
                  style="width: 100%"
                >
                  {{$t("auth.forgotpassword.sentlink")}}
                </button>
              </div>
              <div class="text-center text-blue-400 hover:text-blue-700 mt-3">
                <router-link :to="{ name: 'login' }">
                  &lsaquo; {{$t("auth.forgotpassword.back")}}
                </router-link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
