import { Form, Field } from "vee-validate";
import * as yup from "yup";
import changeLanguage from "@/components/changeLanguage.vue";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import { URL } from "@/api/password";

export default {
  name: "forgotPassword",
  components: {
    Field,
    Form,
    changeLanguage,
    InputWithValidation,
  },
  data() {
    return {
      email: "",
    };
  },
  setup() {
    const forgotPassword = yup.object({
      email: yup
        .string()
        .email("Email không đúng định dạng")
        .required("Email là một trường bắt buộc"),
    });

    return {
      forgotPassword,
    };
  },

  methods: {
    async getLink() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "main" });
        return;
      }
      const data = {};
      data.emailQuanLyTaiKhoan = this.email;
      const result = await this.$request({
        url: URL.FORGOT_PASSWORD,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.success) {
        if (res.code == 200) {
          this.$swal({
            text: res.messages,
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          }).then(() => {
            this.$router.push({ name: "login" });
          });
        }
      }
      if (res.code == 400) {
        this.$swal({
          text: res.errors[0].emailQuanLyTaiKhoan,
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },
  },
};
